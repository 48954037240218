@import "../global";

// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui";

// Fix inaccessible dropdown in .table-responsive dropdown buttons
.table-responsive {
    overflow-x: inherit;
    td:not(.btn-td) {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 200px;
    }
}

// Fix mobile footer
.app-footer {
    flex: 0 0;
    padding-top: map-get($spacers, 3);
    padding-bottom: map-get($spacers, 3);
}

.answer_box {
    label {
        font-weight: bold;
    }

    .divider {
        background: #c8ced3;
        height: 0.5px;
        width: 100%;
        margin: 1.5rem 0;
    }
}
